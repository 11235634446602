<template>
  <div class="card card-body shadow-sm">
    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >
    <app-download-template
      ref="basic_table"
      :table-name="$t('csv_download.csv_download_customer')"
      file-name="基本情報_"
      :filters.sync="filters"
      :endpoint-list="ENDPOINT.CSV_EXPORT_LIST_CUSTOMER"
      :is-download-invoice = false
      :is-download-customer = true
      @resetAllSearch="onResetAllSearch"
      @searched="onSearch"
      @handleAfterError="handleAfterDownloadError"
    >


      <template v-slot:filters>
        <div class="row header-title"><h4><b class="custom_fs_23"> {{ customerExportHeader }}</b></h4></div>
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group">
              <label class="col-xl-4 col-lg-12 col-sm-8">{{ filters.apartment_id_equal.label }}</label>
              <div class="col-xl-12 col-lg-12 col-sm-12">
                <app-select :name="filters.apartment_id_equal.name"
                            input-style="normal"
                            v-model="filters.apartment_id_equal.value"
                            :options-data="meta.apartments" />
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group">
              <label class="col-xl-4 col-lg-6 col-sm-8">{{ filters.from_date_register_between.label }}</label>
              <div class="d-flex justify-content-between">
                <div class="col-xl-9 col-lg-12 col-sm-12">
                  <app-date-range-picker
                    :rules="`from_date_list_page:${filters.to_date_register_between.value}`"
                    :name="filters.from_date_register_between.name"
                    v-model="filters.from_date_register_between.value"
                    timePicker24Hour="false"
                    format="YYYY-MM-DD"

                    @input="filters.from_date_register_between.value = $event"
                  />
                </div>
                <span class="align-self-center align-items-center mr-lg-2 mr-xl-5 pb-4" style="font-size: 30px">~</span>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group">
              <label class="col-xl-4 col-lg-4 col-sm-8" style="opacity: 0">1</label>
              <div class="col-xl-9 col-lg-12 col-sm-12">
                <app-date-range-picker
                  :name="filters.to_date_register_between.name"
                  v-model="filters.to_date_register_between.value"
                  timePicker24Hour="false"
                  format="YYYY-MM-DD"
                  @input="filters.to_date_register_between.value = $event"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xl-4 col-lg-6 col-sm-12">
            <div class="form-group">
              <label class="col-xl-12 col-lg-8 col-sm-8 ">{{ filters.kana_name.label }}</label>
              <div class="col-xl-9 col-lg-9 col-sm-8">
                <app-input :type="filters.kana_name.max"
                           :name="filters.kana_name.name"
                           input-style="normal"
                           type="string128"
                           v-model="filters.kana_name.value"/>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-6 col-sm-12">
            <div class="form-group">
              <label class="col-xl-12 col-lg-12 col-sm-12">{{ filters.gender_code.label }}</label>
              <div class="col-xl-12 col-lg-9 col-sm-9">
                <app-radio v-for="(item, index) in CSV_DOWNLOAD_CONSTANT.gender_code"
                           :isMarginLeft="index !=0 ? true : false" :key="item.id" :label="item.name"
                           :val="item.id" v-model="filters.gender_code.value"/>
              </div>
            </div>
          </div>
        </div>
      </template>
    </app-download-template>

      <app-download-template
        ref="basic_table"
        :table-name="$t('csv_download.csv_download_receipts')"
        :filters.sync="receipt_filters"
        :is-show-reset-button = false
        :is-download-customer = false
        :is-download-invoice = true
        @resetAllSearch="onResetAllSearchReceipt"
        @searched="onSearch"
        @handleAfterError="handleAfterDownloadError"
      >


      <template v-slot:filters>
        <div class="row header-title"><h4><b class="custom_fs_23">{{ receiptsExportHeader }}</b></h4></div>
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group">
              <label class="col-xl-4 col-lg-12 col-sm-8">{{ receipt_filters.apartment_id_equal.label }}</label>
              <div class="col-xl-12 col-lg-12 col-sm-12">
                <app-select :name="receipt_filters.apartment_id_equal.name"
                            input-style="normal"
                            v-model="receipt_filters.apartment_id_equal.value"
                            :options-data="meta.apartments" />
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group">
              <label class="col-xl-4 col-lg-6 col-sm-8">{{ receipt_filters.from_date_receipt_between.label }}</label>
              <div class="d-flex justify-content-between">
                <div class="col-xl-9 col-lg-12 col-sm-12">
                  <app-date-range-picker
                    :rules="`nullable_from_date:${receipt_filters.to_date_receipt_between.value}`"
                    :name="receipt_filters.from_date_receipt_between.name"
                    v-model="receipt_filters.from_date_receipt_between.value"
                    timePicker24Hour="false"
                    format="YYYY-MM-DD"

                    @input="receipt_filters.from_date_receipt_between.value = $event"
                  />
                </div>
                <span class="align-self-center align-items-center mr-lg-2 mr-xl-5 pb-4" style="font-size: 30px">~</span>
              </div>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-sm-4">
            <div class="form-group">
              <label class="col-xl-4 col-lg-4 col-sm-8" style="opacity: 0">1</label>
              <div class="col-xl-9 col-lg-12 col-sm-12">
                <app-date-range-picker
                  :name="receipt_filters.to_date_receipt_between.name"
                  v-model="receipt_filters.to_date_receipt_between.value"
                  timePicker24Hour="false"
                  format="YYYY-MM-DD"
                  @input="receipt_filters.to_date_receipt_between.value = $event"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-end">
          <div class="col-xl-4 col-lg-7 col-sm-7">
            <div class="justify-content-end row">
              <div class="col-xl-8 col-lg-12 col-sm-12 m-r-60">
                <button
                  @click="selectPrevMonth()"
                  class="m-r-15 btn btn-warning"
                  type="button"
                >
                  {{ $t("common.select_prev_month") }}
                </button>
                <button
                  type="button"
                  id="button_next_month"
                  class="btn btn-default m-r-15"
                  @click="selectCurrentMonth()"
                  style="background-color: #ED8077; color: #1f2d3d;"
                >
                  {{ $t("common.select_current_month") }}
                </button>
              </div>
            </div>
          </div>
        </div>

      </template>
    </app-download-template>

    </validation-observer>
  </div>
</template>

<script>
  import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
  import {ENDPOINT} from "../../../constants/api";
  import {FACILITIES_CONSTANT} from "../../../constants/facilities";
  import {CSV_DOWNLOAD_CONSTANT} from "../../../constants/csv_download";
  import AppDownloadTemplate from "@components/_common/download/AppDownloadTemplate";
  import {logoutAction} from "@utils";
  export default {
    name: "csv_download",
    components: {
      "app-download-template": AppDownloadTemplate,
      AppDateRangePicker,
    },
    data() {
      return {
        idCache: null,
        paramDownload: {},
        customerExportHeader: this.$t("csv_download.download_customer_header"),
        receiptsExportHeader: this.$t("csv_download.download_receipt_header"),
        filters: {
          apartment_id_equal: {
            name: "apartment_id",
            condition: "equal",
            label: this.$t("common.mansion")
          },
          from_date_register_between : {
            name: "from_register_date",
            condition: "between",
            label: this.$t("csv_download.register_date")
          },
          to_date_register_between : {
            name: "to_register_date",
            condition: "between",
            label: this.$t("csv_download.register_date")
          },
          gender_code: {
            name: "gender_code",
            condition: "equal",
            label: this.$t("csv_download.gender_code")
          },
          kana_name: {
            name: "kana_name",
            condition: "like",
            label: this.$t("csv_download.name_kana_list_search"),
            max: 'number-length:128'
          }
        },
        receipt_filters: {
          apartment_id_equal: {
            name: "apartment_id",
            condition: "equal",
            label: this.$t("common.mansion")
          },
          from_date_receipt_between : {
            name: "from_receipt_date",
            condition: "between",
            label: this.$t("csv_download.receipt_date")
          },
          to_date_receipt_between : {
            name: "to_receipt_date",
            condition: "between",
            label: this.$t("csv_download.receipt_date")
          }
        },
        meta: {
          apartments: [],
        },
        endPoint: ''
      };
    },
    watch : {
      "filters.from_date_register_between.value" : async function(value) {
        await this.$refs.form_create_entry.validate()
      },
      "filters.to_date_register_between.value" : async function(value) {
        await this.$refs.form_create_entry.validate()
      },
    },
    methods: {
      onResetAllSearch() {
        this.filters.from_date_register_between.value = moment().date(0).format('YYYY-MM-01');
        this.filters.to_date_register_between.value = moment().date(0).format('YYYY-MM-DD');
        this.filters.kana_name.value = '';
        this.receipt_filters.from_date_receipt_between.value = moment().date(0).format('YYYY-MM-01');
        this.receipt_filters.to_date_receipt_between.value = moment().date(0).format('YYYY-MM-DD');
        this.receipt_filters.apartment_id_equal.value = '';
        this.filters.apartment_id_equal.value = '';
      },
      onResetAllSearchReceipt() {
        this.$router.push({name: this.$route.name,
          query: {
            'filters.gender_code.equal' : 'all',
            'filters.from_register_date.between' : moment().date(0).format('YYYY-MM-01'),
            'filters.to_register_date.between' : moment().date(0).format('YYYY-MM-DD'),
            'filters.from_receipt_date.between' : moment().date(0).format('YYYY-MM-01'),
            'filters.to_receipt_date.between' : moment().date(0).format('YYYY-MM-DD'),
          }
        })
      },
      onSearch() {
        // this.getTourSelectName();
      },
      selectPrevMonth() {
        this.receipt_filters.from_date_receipt_between.value = moment().date(0).format('YYYY-MM-01');
        this.receipt_filters.to_date_receipt_between.value = moment().date(0).format('YYYY-MM-DD');
      },
      selectCurrentMonth() {
        this.receipt_filters.from_date_receipt_between.value = moment().startOf('month').format('YYYY-MM-DD');
        this.receipt_filters.to_date_receipt_between.value = moment().format('YYYY-MM-DD');
      },
      async handleAfterDownloadError() {
        await this.getMetaData();
        if (this.meta.apartments.length <= 1) {
          this.logoutAction()
        }
      },
      async getMetaData() {
      await  this.$request.get(this.ENDPOINT.UI_HELPER_CUSTOMER_MEALS).then(res => {
          this.meta = {
            apartments: res.data.apartments,
          }
          this.meta.apartments.unshift({
            id: "",
            name: ""
          })
        })
      }
    },
    mounted: function () {
      this.getMetaData();
    },
  }
</script>

<style scoped>
  .header-title {
    padding-bottom: 2%;
  }
  .custom_fs_23 {
    font-size: 23px;
  }
  #button_next_month:hover {
    background-color: #D8766F !important;
  }
</style>
