<template>
  <div>
    <template v-if="$slots.filters">
      <div class="card card-transparent">
        <div class="card-header search-container">
          <div class="search-content">
            <validation-observer v-slot="{ passes, valid, validated }">
              <form
                novalidate
                autocomplete="off"
                @submit.prevent="passes(searchHandle)"
              >
                <slot name="filters"></slot>
                <div class="m-t-10 row justify-content-center">
                  <button
                    @click="resetAllSearch"
                    class="btn btn-default m-r-15"
                    type="button"
                    v-if="isShowResetButton"
                  >
                    {{ $t("common.reset") }}
                  </button>
                  <button
                    v-if="isDownloadCustomer"
                    :disabled="!valid && validated"
                    class="btn btn-primary text-complete m-r-15"
                    type="submit"
                    @click="downloadCSVData(CSV_DOWNLOAD_CONSTANT.TYPE_DOWNLOAD_CUSTOMER)"
                  >
                    {{ $t("common.download") }}
                  </button>
                  <button
                    v-if="isDownloadInvoice"
                    :disabled="!valid && validated"
                    class="btn btn-primary text-complete m-r-30"
                    type="submit"
                    @click="downloadCSVData(CSV_DOWNLOAD_CONSTANT.TYPE_DOWNLOAD_INVOICE_FACILITY)"
                  >
                    {{ $t("common.download_invoice_facility") }}
                  </button>
                  <button
                    v-if="isDownloadInvoice"
                    :disabled="!valid && validated"
                    class="btn btn-primary text-complete  m-r-30"
                    type="submit"
                    @click="downloadCSVData(CSV_DOWNLOAD_CONSTANT.TYPE_DOWNLOAD_INVOICE_DETAIL_FACILITY)"
                  >
                    {{ $t("common.download_invoice_detail_facility") }}
                  </button>
                  <button
                    v-if="isDownloadInvoice"
                    :disabled="!valid && validated"
                    class="btn btn-primary text-complete m-r-30"
                    type="submit"
                    @click="downloadCSVData(CSV_DOWNLOAD_CONSTANT.TYPE_DOWNLOAD_INVOICE_UNION)"
                  >
                    {{ $t("common.download_invoice_union") }}
                  </button>
                  <button
                    v-if="isDownloadInvoice"
                    :disabled="!valid && validated"
                    class="btn btn-primary text-complete"
                    type="submit"
                    @click="downloadCSVData(CSV_DOWNLOAD_CONSTANT.TYPE_DOWNLOAD_INVOICE_DETAIL_UNION)"
                  >
                    {{ $t("common.download_invoice_detail_union") }}
                  </button>
                </div>
              </form>
            </validation-observer>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import {CSV_DOWNLOAD_CONSTANT, CSV_DOWNLOAD_TYPE_CONSTANT} from "../../../constants/csv_download";
  import {ENDPOINT} from "../../../constants/api";
const EVENT_SEARCH = 'EVENT_SEARCH';
export default {
  name: "AppDownloadTemplate",
  props: {
    filters: {
      type: Object,
      default: {},
    },
    tableName: String,
    endpointList: String,
    fileName: {
      type: String,
      default: 'download'
    },
    isShowResetButton: {
      type: Boolean,
      default: true
    },
    isDownloadCustomer: {
      type: Boolean,
      default: true
    },
    isDownloadInvoice: {
      type: Boolean,
      default: true
    }
  },

  data() {
    var cloneFilters = _.cloneDeep(this.filters);
    _.forEach(this.$route.query, (value, columnString) => {
      if (_.includes(columnString, 'filters.')) {
        let splited = columnString.split('.');
        let name = splited[1];
        let condition = splited[2];
        _.forEach(cloneFilters, (filter, index) => {
          if (filter.name === name && filter.condition === condition) {
            cloneFilters[index] = {...cloneFilters[index], value: value}
          }
        })
      }
    })
    let {search} = this.$route.query;
    return {
      entriesRes: {},
      entries: [],
      loading: true,
      searchCondition: {
        search: search,
        filters: cloneFilters,
      },
      meta: {},
      defaultQuery: this.$route.query,
      urlFileDownloadCustomer: "",
      urlFileDownloadInvoive: ""
    };
  },

  watch: {
    "$route.query": {
      handler: async function (after, before) {
        if (!_.isEqual(after, before)) {
          await this.setSearchStateByRouteQuery()
          this.getEntries(false)
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getEntries(true)
    $(".tooltip").tooltip("hide");
  },
  methods: {
    async downloadCSVData(type){
      const {search} = this.searchCondition
      let params = {
        search: search,
      }
      const filtersClone = _.cloneDeep(this.filters)
      _.forEach(filtersClone, (filter) => {
        if (filter.value && filter.value.toString().length && filter.value.toString() != 'all') {
          params[`filters[${filter.name}][${filter.condition}]`] = filter.value
        }
      })
      if (type == CSV_DOWNLOAD_CONSTANT.TYPE_DOWNLOAD_CUSTOMER) {
        var endpoint = ENDPOINT.CSV_EXPORT_LIST_CUSTOMER;
      } else if (type == CSV_DOWNLOAD_CONSTANT.TYPE_DOWNLOAD_INVOICE_FACILITY) {
        var endpoint = ENDPOINT.CSV_EXPORT_LIST_RECEIPTS;
        var typeExport = CSV_DOWNLOAD_TYPE_CONSTANT.BIZ_TYPE_FACILITY
      } else if (type == CSV_DOWNLOAD_CONSTANT.TYPE_DOWNLOAD_INVOICE_UNION) {
        var endpoint = ENDPOINT.CSV_EXPORT_LIST_RECEIPTS;
        var typeExport = CSV_DOWNLOAD_TYPE_CONSTANT.BIZ_TYPE_UNION
      } else if (type == CSV_DOWNLOAD_CONSTANT.TYPE_DOWNLOAD_INVOICE_DETAIL_FACILITY) {
        var endpoint = ENDPOINT.CSV_EXPORT_LIST_RECEIPTS_DETAIL;
        var typeExport = CSV_DOWNLOAD_TYPE_CONSTANT.BIZ_TYPE_FACILITY
      } else {
        var endpoint = ENDPOINT.CSV_EXPORT_LIST_RECEIPTS_DETAIL;
        var typeExport = CSV_DOWNLOAD_TYPE_CONSTANT.BIZ_TYPE_UNION
      }
      const res = await this.$request.get(endpoint, {...params, typeExport: typeExport})
      if (!res.hasErrors() && type === CSV_DOWNLOAD_CONSTANT.TYPE_DOWNLOAD_CUSTOMER) {
        this.urlFileDownloadCustomer = res.data.result
        var fileURL = this.urlFileDownloadCustomer;
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        document.body.appendChild(fileLink);
        fileLink.click();
      } else if(!res.hasErrors() && type !== CSV_DOWNLOAD_CONSTANT.TYPE_DOWNLOAD_CUSTOMER) {
        this.urlFileDownloadInvoive = res.data.result
        var fileURL = this.urlFileDownloadInvoive;
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        document.body.appendChild(fileLink);
        fileLink.click();
      } else {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
        this.$emit("handleAfterError")
      }

      this.loading = false;
    },
    searchHandle() {
    },
    async getEntries() {
      await this.setSearchStateByRouteQuery()
      const {search} = this.searchCondition
      let params = {
        search: search,
      }
      const filtersClone = _.cloneDeep(this.filters)
      _.forEach(filtersClone, (filter) => {
        if (filter.value && filter.value.toString().length && filter.value.toString() != 'all') {
          params[`filters[${filter.name}][${filter.condition}]`] = filter.value
        }
      })
    },
    async setSearchStateByRouteQuery() {
      var newFilters = _.cloneDeep(this.filters)
      _.forEach(newFilters, (filter, key) => {
        newFilters[key] = {...filter, value: null}
      })
      _.forEach(this.$route.query, (value, columnString) => {
        if (_.includes(columnString, 'filters.')) {
          let splited = columnString.split('.');
          let name = splited[1];
          let condition = splited[2];
          _.forEach(newFilters, (filter, key) => {
            if (filter.name === name && filter.condition === condition) {
              newFilters[key] = {...newFilters[key], value: value}
            }
          })
        }
      })

      await this.$emit('update:filters', newFilters)
    },
    resetAllSearch() {
      if (Object.keys(this.$route.query).length !== 0 && !_.isEqual(this.$route.query, this.defaultQuery)) {
        this.$router.push({name: this.$route.name, query: this.defaultQuery}).catch((e)=>{
          console.log(e);
        });
      } else {
        this.getEntries(false)
      }
      this.$emit('resetAllSearch')
    },
    updateRouteQuery(events) {
      if (!_.isArray(events)) {
        events = [events]
      }
      var queryObj = _.clone(this.$route.query);
      var {search} = this.searchCondition
      _.forEach(events, event => {
        if (event === EVENT_SEARCH) {
          if (search && search.length) {
            queryObj['search'] = search;
          } else {
            delete queryObj['search'];
          }
          const filters = _.cloneDeep(this.filters);
          _.forEach(filters, (filter) => {
            if (filter.value && filter.value.toString().length) {
              queryObj['filters.' + filter.name + '.' + filter.condition] = filter.value.trim()
            } else {
              delete queryObj['filters.' + filter.name + '.' + filter.condition];
            }
          })
        }
      })
      if (!_.isEqual(queryObj, this.$route.query)) {
        this.$router.push({query: queryObj})
      } else {
        this.getEntries(false)
      }
    },
  }
};
</script>

<style>

</style>
